import React from "react"

import Layout from "../components/layout"
import GameCard from "../components/card"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <br />
    <GameCard
      url={"/reversi"}
      image={"reversi.png"}
      name="Play Reversi Online"
    />
  </Layout>
)

export default IndexPage
