import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import Image from "./image"
import styles from "./styles.module.css"

const GameCard = ({ url, image, name }) => (
  <Link className={styles.GameCard} to={url}>
    <div className={styles.GameCard_wrapper}>
      <div className={styles.GameCard_image}>
        <Image fileName={image} />
      </div>
      <div className={styles.GameCard_text}>{name}</div>
    </div>
    <style></style>
  </Link>
)

GameCard.propTypes = {
  url: PropTypes.string,
  image: PropTypes.string,
  name: PropTypes.string,
}

GameCard.defaultProps = {
  url: "",
  image: "",
  name: "",
}

export default GameCard
